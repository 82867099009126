import React from 'react';
import Layout from '../components/Layout';

const InformationSubmittedPage = () => {
    return (
        <Layout>
            <div className="container-fluid p-0" id="page-top">
                <section className="resume-section p-3 p-lg-5 d-flex align-items-center">
                    <div className="w-100">
                        <h1 className="mb-0">Information <span className="text-primary">Received</span></h1>
                        <p className="mb-5">Thank you for submitting your information. If you have not already donated, please head
                            over to the donation page and review all the donation information.</p>
                    </div>
                </section>
            </div>
        </Layout>
    )
};

export default InformationSubmittedPage;
